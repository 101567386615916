import { Card } from 'primereact/card';
import React, { useEffect, useRef, useState } from 'react';
import jwt_decode from "jwt-decode";

export default function StatusMessages(props: any) {
    const [selectedParcel, setSelectedParcel] = useState(props.selectedParcel);
    const [selectedParcelId, setSelectedParcelId] = useState(props.selectedParcel?.parcelID);
    const [currentUserId, setCurrentUserId] = useState(0);





    useEffect(() => {
        setSelectedParcel(props.selectedParcel);
    }, [props.selectedParcel]);

    useEffect(() => {
        if (localStorage.getItem("token")) {
            setCurrentUserId((jwt_decode(localStorage.getItem("token") as string) as any).UserId);
            props.getCurrentUser((jwt_decode(localStorage.getItem("token") as string) as any).UserId);
        }
    }, []);



    return (
        <React.Fragment>

            {/* LEGAL STATUS */}
            {(selectedParcel?.sentLegalFile && selectedParcel.noPayoffAfterLegal && !!!selectedParcel.hasPPS)
                && (
                    <React.Fragment>
                        <div className="p-grid">
                            <div className="p-col">
                                <Card title={`ATTENTION`} className={"red-text"}>
                                    Our records indicate that parcel number {selectedParcel.parcelNo} is in a legal status. For information regarding the payoff amount or payment plan options, please contact our Servicing Department via email at  <a href="mailto:servicing@usassets.net">servicing@usassets.net</a> or by calling 1-844-216-4341.
                                </Card>
                            </div>
                        </div>
                        <br />
                    </React.Fragment>
                )}


            {/* PAYMENT PLAN NO DEFAULT STATUS */}
            {selectedParcel?.hasPPS && currentUserId !== 0 && (
                <React.Fragment>
                    <div className="p-grid">
                        <div className="p-col">
                            <Card title={`ATTENTION`} className={"red-text"}>
                                Our records indicate tax certificate {selectedParcel.taxSaleNo} is in an active payment plan. You can click{" "}
                                <span className="green-text">"Make a Monthly Payment"</span> above to make a payment on this plan. If you would like to know the balance due or
                                have additional questions regarding your payment plan, please contact us at <a href="mailto:servicing@usassets.net">servicing@usassets.net</a>{" "}
                                or 1-844-216-4341.
                            </Card>
                        </div>
                    </div>
                    <br />
                </React.Fragment>
            )}




            {/* PAYMENT PLAN DEFAULT STATUS */}
            {/* SEEMS IDENTICAL TO THE NON DEFAULT TEXT */}
            {selectedParcel?.hasPPS && selectedParcel?.payPlanStatus != "DEFAULT" && currentUserId === 0 && (
                <React.Fragment>
                    <div className="p-grid">
                        <div className="p-col">
                            <Card title={`ATTENTION`} className={"red-text"}>
                                Our records indicate tax certificate {selectedParcel.taxSaleNo} is in an active payment plan. You can click{" "}
                                <a href={`/login?parcelId=${selectedParcelId}`}>here</a> to login and make a payment on this plan. If you would like to know the balance due or
                                have additional questions regarding your payment plan, please contact us at <a href="mailto:servicing@usassets.net">servicing@usassets.net</a>{" "}
                                or 1-844-216-4341.
                            </Card>
                        </div>
                    </div>
                    <br />
                </React.Fragment>
            )}



            {/* PAYMENT PENDNG STATUS */}
            {((selectedParcel?.paymentPending != null && selectedParcel.paymentPending) || (selectedParcel?.redemptionInProcDate != null)) && selectedParcel.rtReceivedDate == null && (
                <div>
                    <div className="p-message p-component p-message-success p-message-enter-done">
                        <div className="p-m-2">
                            <br />
                            <h4>Payment has been submitted and is awaiting processing. </h4>
                            <br />
                        </div>
                    </div>
                </div>
            )}



            {/* REQUIRES DIRECT PAYOFF STATUS */}
            {((selectedParcel?.statusID == 23 || (selectedParcel?.hasPPS && selectedParcel?.payPlanStatus === "DEFAULT") || selectedParcel?.moneyInEscrow !== 0) && !selectedParcel?.activePPS) && (
                <React.Fragment>
                    <div className="p-grid">
                        <div className="p-col">
                            <Card title={`ATTENTION`} className={"red-text"}>
                                Tax Certificate {selectedParcel?.taxSaleNo} requires a payoff to be obtained directly from Adair Asset Management's Staff. To obtain the payoff
                                please contact us as <a href="mailto:servicing@usassets.net">servicing@usassets.net</a> or 1-844-216-4341.
                            </Card>
                        </div>
                    </div>
                    <br />
                </React.Fragment>
            )}



            {/* PENDING SHERIFF SALE STATUS */}
            {((selectedParcel?.sheriffSaleDate && new Date(selectedParcel?.sheriffSaleDate) > new Date()) ||
                (selectedParcel?.sheriffSaleDate && selectedParcel.mustGetPayoffFromCountyIfSheriffSaleDate))
                && (
                    <React.Fragment>
                        <div className="p-grid">
                            <div className="p-col">
                                <Card title={``}>
                                    Our records indicate parcel number {selectedParcel.parcelNo} has been scheduled for sheriff sale via case number {selectedParcel.caseNum}. In
                                    order to redeem the taxes for this parcel at this stage of the process, you must also pay current taxes and court costs directly to the Summit
                                    County Fiscal Office. Please contact the Summit County Fiscal Office directly at 1-330-643-2600 for all payoff amounts and due dates.
                                </Card>
                            </div>
                        </div>
                        <br />
                    </React.Fragment>
                )}



            {/* SHERIFF SALE PASSED STATUS */}
            {selectedParcel?.sheriffSaleDate && new Date(selectedParcel?.sheriffSaleDate) < new Date() && !selectedParcel?.mustGetPayoffFromCountyIfSheriffSaleDate && (
                <React.Fragment>
                    <div className="p-grid">
                        <div className="p-col">
                            <Card title={``}>
                                Our records indicate parcel number {selectedParcel.parcelNo} has gone through sheriff sale via case number {selectedParcel.caseNum} and may be
                                past the stage where taxes can be redeemed. Please contact us directly with any inquiries at{" "}
                                <a href="mailto:servicing@usassets.net">servicing@usassets.net</a> or 1-844-216-4341.
                            </Card>
                        </div>
                    </div>
                    <br />
                </React.Fragment>
            )}






            {/* PAYMENT PLAN DEFAULT STATUS */}
            
        </React.Fragment>
    )
}