import { Card } from "primereact/card";
import * as React from "react";
import { connect } from "react-redux";

const Disclaimer = () => (
  <Card className="card-full-width" title={`Disclaimer`} subTitle="">
    <p>
      While all the information contained on this site is believed to be accurate, US Assets, LLC makes no warranty as to the completeness or accuracy, nor can
      it accept responsibility for errors contained in this content. US Assets, LLC and its employees, agents, affiliated companies and officers make no
      warranty or representation, express or implied, with respect to the quality, content, accuracy or currency of content on this website. The information
      herein is subject to change at any time. In no event shall US Assets, LLC be liable for any special, direct, indirect, consequential or incidental damages
      or any damages whatsoever, whether in an action of contract, negligence or other tort, arising out of or in connection with the use of this site.
    </p>
  </Card>
);

export default connect()(Disclaimer);
